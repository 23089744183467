/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import EventCard from "./event-card"
import CollectionCard from "./collection-card"
import Ad from "./ad"
import CTA from "./cta"
import { useMedia } from "react-use"
import { mediaQueryList } from "../helpers"
import { motion, AnimatePresence } from "framer-motion"

const EventList = ({
  events,
  collections,
  title,
  type,
  desktopAdUrl,
  mobileAdUrl,
  defaultShowAll,
}) => {
  const all = React.useMemo(
    () =>
      collections &&
      events &&
      [
        ...events.map(event => ({
          type: "event",
          endDate: event.fields.eventEndDate,
          entry: event,
        })),
        ...collections.map(collection => ({
          type: "collection",
          endDate: collection.fields.endDate,
          entry: collection,
        })),
      ].sort((a, b) => new Date(a.endDate) - new Date(b.endDate)),
    [collections, events]
  )

  const renderEntry = (type, entry) => {
    if (type === "event")
      return (
        <EventCard
          sx={{ flexGrow: 1, margin: "1px", height: "100%" }}
          event={{
            ...entry.fields,
            contentful_id: entry.sys.id,
            eventImage:
              entry.fields.eventImage && entry.fields.eventImage.fields,
            organization:
              entry.fields.organization && entry.fields.organization.fields,
            experience:
              entry.fields.experience && entry.fields.experience.fields,
            discipline:
              entry.fields.discipline && entry.fields.discipline.fields,
            venue: entry.fields.venue && entry.fields.venue.fields,
            collection:
              entry.fields.collection && entry.fields.collection.fields,
          }}
        />
      )
    return (
      <CollectionCard
        sx={{ flexGrow: 1, margin: "1px", height: "100%" }}
        collection={{
          id: entry.sys.id,
          image: entry.fields.image.fields.file.url,
          disciplineName: entry.fields.discipline?.fields.disciplineName,
          name: entry.fields.name,
          description: entry.fields.description,
          startDate: entry.fields.startDate,
          endDate: entry.fields.endDate,
          organizationName: entry.fields.organization.fields.name,
        }}
      />
    )
  }

  const [showAll, setShowAll] = React.useState(false)
  const isTablet = useMedia(mediaQueryList({ mediaPrefix: false })[1])
  const isDesktop = useMedia(mediaQueryList({ mediaPrefix: false })[2])

  const limit = React.useMemo(() => {
    return isDesktop ? 4 : isTablet ? 3 : 4
  }, [isTablet, isDesktop])

  const items = defaultShowAll || showAll ? all : all?.slice(0, limit)

  return (
    <div>
      <Ad
        desktopAdUrl={desktopAdUrl}
        mobileAdUrl={mobileAdUrl}
        sx={{ mb: "20px" }}
      />
      {all ? (
        <div>
          <div>
            {title && (
              <h3
                sx={{
                  fontSize: "30px",
                  ...(type === "secondary"
                    ? { mb: "10px" }
                    : {
                        mb: "35px",
                        pb: "15px",
                        borderBottom: "1px solid #c0c0c0",
                      }),
                }}
              >
                {title}
              </h3>
            )}
          </div>
          <div
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            }}
          >
            <AnimatePresence>
              {items.map(({ type, entry }, index) => (
                <motion.div
                  key={index}
                  sx={{
                    width: "100%",
                    height: "100%",
                    pb: 6,
                  }}
                  initial={{
                    transform: index > 3 ? "translatey(-20px)" : "unset",
                    opacity: index > 3 ? 0 : "unset",
                  }}
                  animate={{
                    transform: index > 3 ? "translatey(0px)" : "unset",
                    opacity: index > 3 ? 1 : "unset",
                  }}
                  transition={{ duration: 0.6 }}
                >
                  <div
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    {renderEntry(type, entry)}
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "30px",
            }}
          >
            {!defaultShowAll && !showAll && all.length > limit && (
              <CTA onClick={() => setShowAll(true)}>See All</CTA>
            )}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default EventList
